import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";

@Injectable()
export class SpinnerService implements OnDestroy {

  private shown: number = 0;
  private opened: MatDialogRef<SpinnerComponent>;
  private med: Subject<number> = new Subject<number>();
  private medSub: Subscription;
  private isOpen: boolean = false;

  constructor(
    private modalService: MatDialog,
  ) {
    this.init();
  }

  show(): void {
    this.med.next(1);
  }

  hide(): void {
    this.shown--;
    if (this.shown === 0) {
      this.isOpen = false;
      this.opened.close();
    }
  }

  hidden(): boolean {
    return !this.isOpen;
  }

  isLoading(): boolean {
    return this.shown > 0;
  }

  private init(): void {
    this.medSub = this.med.pipe(delay(130)).subscribe(value => {
      this.shown++;
      if (this.shown > 0 && !this.isOpen) {
        this.isOpen = true;
        this.openModal();
      }
    });
  }

  private openModal(): void {
    const options: MatDialogConfig = {
      panelClass: 'spinner',
      height: '200px',
      width: '200px',
      disableClose: true,
      autoFocus: false,
    };
    this.opened = this.modalService.open(SpinnerComponent, options);
  }

  ngOnDestroy(): void {
    this.medSub.unsubscribe();
  }
}
