import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'gryf-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
