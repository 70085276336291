import {Routes} from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/web-app/web-app.module').then(m => m.WebAppModule)
  },
  {
    path: '404',
    loadChildren: () => import('./components/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {path: '**', redirectTo: '/404'}
];
