import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {SpinnerService} from './shared/services/spinner.service';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    private spinnerService: SpinnerService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.initializeGlobalObject();
  }

  ngOnInit(): void {
    this.spinnerService.show();
  }

  private initializeGlobalObject() {
    const isBrowser = isPlatformBrowser(this.platformId);
    if (!isBrowser) {
      global['window'] = {} as any;
      global['document'] = {
        getComputedStyle: (): any => ({}),
        getElementById: (): any => ({}),
        doc: {} as any
      } as any;
    }
  }
}
